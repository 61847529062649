import Graticule from 'ol/layer/Graticule';
import Stroke from 'ol/style/Stroke';

import Map from 'ol/Map';
import View from 'ol/View';
import OSM from 'ol/source/OSM';

import {getCenter} from 'ol/extent.js';

import ImageLayer from 'ol/layer/Image';
import TileLayer from 'ol/layer/Tile';
import {transform, fromLonLat} from 'ol/proj';
import Static from 'ol/source/ImageStatic.js';

import ScaleLine from 'ol/control/ScaleLine';

import Projection from 'ol/proj/Projection';
import {register} from 'ol/proj/proj4.js';
import proj4 from 'proj4';

import {ZoomToExtent} from 'ol/control';
import * as dayjs from 'dayjs';

const day0 = dayjs();
const YMD = day0.format('YYYYMMDD');

const base_path = 'cached-images'; // local cached images base path
const lustre_base_path = 'fwi-images'; // remote lustre images base path

var path0 = base_path+'/FWI_forecast_'+YMD+'_day+0.png'
var path1 = base_path+'/FWI_forecast_'+YMD+'_day+1.png'
var path2 = base_path+'/FWI_forecast_'+YMD+'_day+2.png'

var tryRequire = (path0) => { // test if image0 is present locally
  try {
   return require(`${path0}`);
  } catch (err) {
   return null;
  }
};

path0 = tryRequire(path0) ? path0 : lustre_base_path+'/Image0/FWI_forecast_'+YMD+'_day+0.png';

tryRequire = (path1) => { // test if image1 is present locally
  try {
   return require(`${path1}`);
  } catch (err) {
   return null;
  }
};

path1 = tryRequire(path1) ? path1 : lustre_base_path+'/Image1/FWI_forecast_'+YMD+'_day+1.png';

tryRequire = (path2) => { // test if image2 is present locally
  try {
   return require(`${path2}`);
  } catch (err) {
   return null;
  }
};

path2 = tryRequire(path2) ? path2 : lustre_base_path+'/Image2/FWI_forecast_'+YMD+'_day+2.png';

//const Image0 = "https://skogbrannfare.k8s.met.no/fwi-images/Image0/FWI_forecast_20210427_day+0.png";
var Image0 = path0; //+'/FWI_forecast_'+YMD+'_day+0.png';
var Image1 = path1; //'fwi-images/Image1/FWI_forecast_'+YMD+'_day+1.png'; // testing latency of lustre access vs local access
var Image2 = path2; //+'/FWI_forecast_'+YMD+'_day+2.png';
//var Image0 = "/images/FWI_forecast_"+YMD+"_day+0.png";
//var Image1 = "/images/Image1/FWI_forecast_"+YMD+"_day+1.png";
//var Image2 = "/images/FWI_forecast_"+YMD+"_day+2.png";

proj4.defs('lcc', '+proj=lcc +lat_0=63 +lon_0=15 +lat_1=63 +lat_2=63 +R=6.371e+06 +datum=WGS84 +no_defs')
register(proj4)

/*
  Extent is given by [min x, min y, max x, max y]

  In the netCDF file minimum and maximum for the model area is set to
  latitude 51 to 88 and longitude to -20 to 80.

  However the extent of the data-set is much smaller.  The lower left
  hand corner is at 52.30272N 1.918465E and the upper right hand corner
  is at 72.18485N 41.76428E

  The following values have been collected from the NetCDF file
*/

/* some minor adjustment to the extent to get the overlay to fit
 * better with the background map */

//var imageExtent = [-897442.0, -1104322.0, 897558.0 , 1215678.0 + 15000]; //settled on skogbrannfare.met.no

//var imageExtent = [-897442.0, -1104322.0, 897558.0 , 1215678.0 + 25000]; //new approximation for north (work in progress) 
var imageExtent = [-897442.2, -1104322.0, 897557.8 , 1215678.0]; //TEST

/*
1st index:
2nd index: moves bottom up
3rd index: moves right edge to the right
4th index: 
*/


/*
  Given that the extent of the image is limited, we can limit the map
  to an area just larger than the area covered by the dataset

  The area selected is
*/

var mapExtent = [-1087158.85, -1329590.95, 986554.94, 1360780.73];

/* center arround 64N 14E */

//var centerExtent = [-269430.90 - 600000, -211954.21 - 100000, -269430.90 + 600000, -211954.21 + 100000];
var centerExtent = [-48750.19 - 600000, 111579.69 - 100000, -48750.19 + 600000, 111579.69 + 100000];
//var centerExtent = [-48000.19 - 600000, 111579.69 - 100000, -48000.19 + 600000, 111579.69 + 100000];

var LambertConformalConic = new Projection({
    code: 'lcc',
    global: false,
    extent: mapExtent,
    worldExtent: mapExtent
});

var layer0 = new TileLayer({
    preload: Infinity,
    source: new OSM({
	"url" : "https://{a-c}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
    })
});

var layer1 = new TileLayer({
    preload: Infinity,
    source: new OSM({
	"url" : "https://{a-c}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
    })
});

var layer2 = new TileLayer({
    preload: Infinity,
    source: new OSM({
	"url" : "https://{a-c}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
    })
});

/* rotate view 30 degrees = about 0.5 rad */

var mview = new View({
    projection: LambertConformalConic,
    /*center: fromLonLat([12,63], LambertConformalConic),*/
    center: transform(getCenter(centerExtent), LambertConformalConic, LambertConformalConic),
    zoom: 2,
    maxZoom: 9,
    minZoom: 2,
    //rotation: -0.2,
});

var map0 = new Map({
    target: 'map0',
    layers: [layer0],
    view: mview
});

var map1 = new Map({
    target: 'map1',
    layers: [layer1],
    view: mview
});

var map2 = new Map({
    target: 'map2',
    layers: [layer2],
    view: mview
});

var fwi_image_0 = new ImageLayer({
    opacity: 0.55,
    source: new Static({
        url: Image0,
	    imageExtent: imageExtent,
	    crossOrigin: ''
    })
})

var fwi_image_1 = new ImageLayer({
    opacity: 0.55,
    source: new Static({
        url: Image1,
	    imageExtent: imageExtent,
	    crossOrigin: ''
    })
})

var fwi_image_2 = new ImageLayer({
    opacity: 0.55,
    source: new Static({
        url: Image2,
	    imageExtent: imageExtent,
	    crossOrigin: ''
    })
})

map0.addLayer(fwi_image_0);
map1.addLayer(fwi_image_1);
map2.addLayer(fwi_image_2);

map0.addControl(new ScaleLine());
map1.addControl(new ScaleLine());
map2.addControl(new ScaleLine());

var home_zoom0 = new ZoomToExtent({
    extent: centerExtent,
    label: 'Z',
    tipLabel: "'Zero zoom' - zoom to full map"
});

var home_zoom1 = new ZoomToExtent({
    extent: centerExtent,
    label: 'Z',
    tipLabel: "'Zero zoom' - zoom to full map"
});

var home_zoom2 = new ZoomToExtent({
    extent: centerExtent,
    label: 'Z',
    tipLabel: "'Zero zoom' - zoom to full map"
});

map0.addControl(home_zoom0);
map1.addControl(home_zoom1);
map2.addControl(home_zoom2);
